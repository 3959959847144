import axios from 'axios';
import { API_URL } from '@/lib/config.ts';
import { toast } from 'sonner';

const axiosInstance = axios.create({
  baseURL: API_URL,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Values are stored as strings in localStorage by useLocalStorage hook
    const accessToken: string | null = JSON.parse(localStorage.getItem('access_token') ?? '');

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(error);

    // If the error is not a 401, redirect lo login page if not already there
    if (
      error.response.status === 401 &&
      !window.location.pathname.includes('login') &&
      !window.location.pathname.includes('logout')
    ) {
      console.debug('Session expired!');
      // Redirect to logout page
      const message = 'Su sesión ha expirado. Por favor, inicie sesión nuevamente.';
      window.location.href = `/logout?message=${message}`;
      return Promise.reject(error);
    }

    // If we're in development, show the error message
    if (process.env.NODE_ENV === 'development') {
      toast.error(error.message ?? 'An error occurred');
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
