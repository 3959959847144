import client from '@/lib/cinepolis/client.ts';
import {
  Aggregations,
  Billboard,
  BillboardResponse,
  Movie,
  SlidesResponse,
  Template,
} from '@/lib/cinepolis/movies/contracts.ts';
import { AggregatedResponse, Message } from '@/lib/cinepolis/contracts.ts';
import { Showtime } from '@/lib/cinepolis/showtimes/contracts.ts';

export async function show(movieId: number): Promise<Movie> {
  const response = await client.get<Movie>(`movies/${movieId}`, {
    params: {
      include: 'videos',
    },
  });
  return response.data;
}

export async function aggregations(
  movieId: number,
  params?: {
    date?: string;
    complex?: number;
  },
): Promise<Aggregations> {
  const response = await client.get<Aggregations>(`movies/${movieId}/aggregations`, { params });
  return response.data;
}

export async function showtimes(
  movieId: number,
  params?: {
    screens?: string;
    formats?: string;
    versions?: string;
    dates?: string;
  },
): Promise<AggregatedResponse<Showtime[]>> {
  const response = await client.get<AggregatedResponse<Showtime[]>>(`movies/${movieId}/showtimes`, { params });
  return response.data;
}

export async function messages(movieId: number): Promise<Message[]> {
  const response = await client.get<Message[]>(`movies/${movieId}/messages`);
  return response.data;
}

export async function billboard(): Promise<Billboard[]> {
  const { data } = await client.get<BillboardResponse>(`movies`);
  return data.data;
}

export async function upcomingReleases(): Promise<Billboard[]> {
  const { data } = await client.get<Billboard[]>(`upcoming-releases`, { params: { limit: 15 } });
  return data;
}

export async function slides(): Promise<Template[]> {
  const { data } = await client.get<SlidesResponse[]>(`slides`);
  const slides = data.map((item: SlidesResponse) => item.template);
  return slides;
}
