import { useMemo } from 'react';
import imgEntradas from '@/assets/benefits/2entradas.jpeg';
import imgCandy from '@/assets/benefits/candy-1.jpeg';
import imgPrice from '@/assets/benefits/mPrice.jpeg';
import imgAvant from '@/assets/benefits/avant.jpeg';
import imgReloj from '@/assets/benefits/reloj.jpeg';

export default function useBenefits() {
  return useMemo(() => {
    return [
      {
        img: imgEntradas,
        text: '2 entradas',
        description: (
          <p>
            2 entradas por mes válidas en salas <strong>tradicionales</strong> y <strong>MONSTER SCREEN</strong>, en
            formato <strong>2D y 3D</strong>.
          </p>
        ),
      },
      {
        img: imgCandy,
        text: 'Descuentos en Candy',
        description: (
          <p>
            Anunciate como Socio MovieClub con tu tarjeta de crédito asociada y obtené descuentos en tu compra en el
            Candy Bar
            <strong>35% off combos POP</strong>
            <strong>20% off todos los combos</strong>
            <strong>20% off combos Premium</strong>
          </p>
        ),
      },
      {
        img: imgPrice,
        text: 'MoviePrice',
        description: (
          <p>
            <strong>50% de descuento</strong> todos los días en <strong>salas tradicionales</strong> y{' '}
            <strong>MonsterScreen</strong>, en formato <strong>2D y 3D.</strong>
            <strong>25% de descuento</strong> en <strong>salas 4D</strong> de lunes a viernes
          </p>
        ),
      },
      {
        img: imgAvant,
        text: 'Avant Premiere',
        description: <p>Te invitamos a ser parte de eventos exclusivos</p>,
      },
      {
        img: imgReloj,
        text: 'Venta Anticipada',
        description: (
          <p>
            Conseguí tu lugar antes que nadie para ver <strong>esas pelis que todos quieren ver.</strong>
          </p>
        ),
      },
    ];
  }, []);
}
