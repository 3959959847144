import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { useTimeout } from '@raddix/use-timeout';
import { Link } from '@tanstack/react-router';
import Loading from '@/components/common/loading';
import { Template } from '@/lib/cinepolis/movies/contracts';

type Props = {
  items: Template[] | undefined;
  current: number;
  animDuration?: number;
  autoPlay?: boolean;
  playDelay?: number;
  isLoading?: boolean;
};

export default function Carousel({
  items = [],
  current = 0,
  animDuration = 1000,
  autoPlay = true,
  playDelay = 6000,
  isLoading = true,
}: Props) {
  const [showArrows, setShowArrows] = useState<boolean>(false);
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [currentImg, setCurrentImg] = useState<number>(current);
  const playTimer = useTimeout(() => {
    if (isPaused) return;
    change('forward');
  }, playDelay);

  const change = useCallback(
    (direction: 'forward' | 'back') => {
      if (direction == 'forward') {
        if (currentImg >= items.length - 1) {
          setCurrentImg(0);
        } else {
          setCurrentImg(currentImg + 1);
        }
      } else {
        if (currentImg <= 0) {
          setCurrentImg(items.length - 1);
        } else {
          setCurrentImg(currentImg - 1);
        }
      }
      if (autoPlay && !isPaused) {
        playTimer.reset();
      }
    },
    [autoPlay, currentImg, isPaused, items.length, playTimer],
  );

  const handleMouseEnter = () => {
    playTimer.clear();
    setIsPaused(true);
    setShowArrows(true);
  };

  const handleMouseLeave = () => {
    playTimer.reset();
    setIsPaused(false);
    setShowArrows(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validateNode = (node: any, key: string) => {
    if (!node) return false;
    if (key in node) {
      return !!node[key];
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (autoPlay) {
      playTimer.run();
    } else {
      playTimer.clear();
    }
  }, [autoPlay, playTimer]);

  return (
    <>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="relative h-full max-h-[800px] w-full bg-black"
        style={{ aspectRatio: '16/9' }}
      >
        {isLoading ? (
          <div className="flex h-full w-full items-center justify-center">
            <Loading />
          </div>
        ) : (
          items.length &&
          items.map((item, index) => {
            return (
              <div
                key={index}
                className={cn(
                  `absolute left-0 top-0 max-h-[800px] w-full transition-opacity ease-in-out`,
                  currentImg === index ? 'z-10' : 'z-0',
                  currentImg === index ? 'opacity-100' : 'opacity-0',
                )}
                style={{
                  aspectRatio: '16/9',
                  transitionDuration: `${animDuration}ms`,
                  backgroundImage: `url(${encodeURI(item.backdrops?.large ?? item?.images?.large ?? '')
                    .replace(/\(/g, '%28')
                    .replace(/\)/g, '%29')})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              >
                <div
                  className={cn(
                    'absolute left-[15%] top-2/3 z-20 text-white transition-all duration-1000 ease-in-out direction-normal md:left-44',
                    currentImg === index
                      ? 'top-[35%] block opacity-100 delay-300 sm:top-[45%]'
                      : 'top-2/3 opacity-0 delay-1000',
                  )}
                >
                  <h2 className="poppins-bold text-xl font-extrabold [text-shadow:_0_1px_3px_rgb(0_0_0_/_40%)] md:text-4xl">
                    {item?.movie?.title_translated}
                  </h2>
                  <p className="mb-2 mt-0 flex gap-2 text-xs text-[#8ce906] [text-shadow:_0_1px_3px_rgb(0_0_0_/_40%)] sm:mb-4 sm:mt-2 sm:text-[13px]">
                    <span>{validateNode(item?.movie, 'category') ? item?.movie?.category : ''}</span>
                    <span>{validateNode(item?.movie, 'runtime') ? `${item?.movie?.runtime}min` : ''}</span>
                    <span>{validateNode(item?.movie, 'parental_guide') ? `${item?.movie?.parental_guide}` : ''}</span>

                    {/* {item?.movie?.category} - {item?.movie?.runtime}min - {item?.movie?.parental_guide} */}
                  </p>
                  <Link to={`/peliculas/${item?.movie?.id}`} className="inline-block">
                    <Button size="md" variant="inverted" className="px-4 py-1 text-xs sm:px-6 sm:py-2 sm:text-base">
                      Ver Trailer
                    </Button>
                  </Link>
                </div>
                <div className="pointer-events-none absolute left-0 top-0 z-10 h-full w-full bg-black opacity-30"></div>
              </div>
            );
          })
        )}
        <div className={cn('absolute left-0 top-[40%] z-20 h-fit w-fit cursor-pointer overflow-hidden sm:top-1/2')}>
          <div
            className={cn(
              'z-30 flex w-10 justify-end overflow-hidden transition-all duration-500 ease-in-out sm:w-20',
              showArrows ? 'translate-x-0 ' : 'translate-x-0 md:-translate-x-20',
            )}
            onClick={() => change('back')}
          >
            <ChevronLeft size={48} className="text-white" />
          </div>
        </div>
        ;
        <div className={cn('absolute right-0 top-[40%] z-20 h-fit w-fit cursor-pointer overflow-hidden sm:top-1/2')}>
          <div
            className={cn(
              'z-30 flex w-10 justify-start overflow-hidden transition-all duration-500 ease-in-out sm:w-20',
              showArrows ? 'translate-x-0 ' : 'translate-x-0 md:translate-x-20',
            )}
            onClick={() => change('forward')}
          >
            <ChevronRight size={48} className="text-white" />
          </div>
        </div>
        ;
      </div>
    </>
  );
}
