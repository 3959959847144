import { AxiosError } from 'axios';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { toast } from 'sonner';

export function handleServerErrors<T extends FieldValues>(form: UseFormReturn<T>, exception: AxiosError | unknown) {
  // If instance is not an AxiosError, it's not a server error, so we throw it
  if (!(exception instanceof AxiosError)) {
    console.warn('Unknown error', exception);
    throw exception;
  }

  if (exception.response?.status === 422) {
    const errors: { [P in keyof T]?: string[] } = exception.response.data.errors;
    const fieldNames = Object.keys(form.getValues());

    Object.keys(errors).forEach((key) => {
      // If key is not present in form, display toast
      if (!fieldNames.includes(key)) {
        console.warn(`Field ${key} not found in form`);
        toast.error(errors[key as keyof T]!.join('. '));
        return;
      }

      form.setError(key as 'root', {
        type: 'server',
        message: errors[key as keyof T]!.join('. '),
      });
    });
  } else {
    console.error(exception);
  }
}
