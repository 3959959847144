import { createFileRoute } from '@tanstack/react-router';
import { useQuery } from '@tanstack/react-query';
import { getMemberItems } from '@/lib/members';
import { Skeleton } from '@/components/ui/skeleton.tsx';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table.tsx';
import React from 'react';
import { Helmet } from 'react-helmet-async';

export const Route = createFileRoute('/_checkAuth/_layout/_authenticated/_member/_layout/miembros')({
  component: () => <Benefits />,
});

function Benefits() {
  const { data, isLoading } = useQuery({
    queryKey: ['members-items'],
    queryFn: getMemberItems,
  });

  const showPoints = !!data && data.items.points !== undefined;

  return (
    <>
      <Helmet>
        <title>Mis beneficios | MovieClub</title>
      </Helmet>

      {showPoints && (
        <>
          <h2 className=" mt-5 text-xl font-bold">Tus Puntos</h2>
          <p className="text-xs text-gray-500 sm:text-sm">Balance de puntos disponibles</p>
          <div className="mt-4 flex h-[90px] w-full items-center justify-center rounded-sm border border-muted px-8 py-6 text-2xl font-bold">
            <span>{data && data.items.points}</span>
          </div>
        </>
      )}

      <h2 className="text-xl font-bold">Tus Beneficios</h2>
      <p className="text-xs text-gray-500 sm:text-sm">Estos son los beneficios que tenes disponibles</p>
      <div className="mt-4 w-full rounded-sm border border-muted px-8 pt-6">
        <Table className="sm:w-full">
          <TableHeader>
            <TableRow>
              <TableHead className="w-full">Beneficio</TableHead>
              <TableHead>Cantidad</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={4} className="text-xs sm:text-base [&>*]:mb-2">
                  <Skeleton className="h-[20px] w-full rounded-sm bg-muted" />
                  <Skeleton className="h-[20px] w-full rounded-sm bg-muted" />
                  <Skeleton className="h-[20px] w-full rounded-sm bg-muted" />
                </TableCell>
              </TableRow>
            ) : data && data.items.benefits.length <= 0 ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <div className="w-full text-center text-secondary">No tienes beneficios</div>
                </TableCell>
              </TableRow>
            ) : (
              React.Children.toArray(
                data?.items.benefits.map((td) => {
                  return (
                    <TableRow>
                      <TableCell>
                        <div>{td.name}</div>
                        <small>{td.description}</small>
                      </TableCell>
                      <TableCell className="text-center">{td.points}</TableCell>
                    </TableRow>
                  );
                }) ?? [],
              )
            )}
          </TableBody>
        </Table>
      </div>
    </>
  );
}
