import Benefit from '@/components/benefit';
import HomeCarousel from '@/components/carousel';
import MoviesBillboard from '@/components/moviesBillboard';
import { Button } from '@/components/ui/button';
import Footer from '@/components/ui/footer';
import Header from '@/components/ui/header';
import useBenefits from '@/hooks/useBenefits';
import { billboard, slides as getSlides, upcomingReleases } from '@/lib/cinepolis/movies/api';
import { useQuery } from '@tanstack/react-query';
import { createFileRoute, Link } from '@tanstack/react-router';
import BrandsGrid from '@/lib/brands/components/brands-grid.tsx';
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@/components/ui/carousel.tsx';
import { Skeleton } from '@/components/ui/skeleton.tsx';
import React, { useMemo } from 'react';
import MoviePoster from '@/components/moviePoster';
import { MovieTag } from '@/lib/movies/constants.ts';
import { useAuthState } from '@/lib/auth/hooks/useAuthState.ts';

export const Route = createFileRoute('/_checkAuth/')({
  component: () => <HomePage />,
});

function HomePage() {
  const { isAuthenticated, login } = useAuthState();
  const benefits = useBenefits();

  const { data, isLoading } = useQuery({
    queryKey: ['slides'],
    queryFn: getSlides,
  });

  const { data: upcoming, isLoading: isLoadingUpcoming } = useQuery({
    queryKey: ['upcoming-releases'],
    queryFn: upcomingReleases,
  });

  // Filter not movie slides
  const slides = useMemo(() => {
    return data?.filter((slide) => !!slide.movie);
  }, [data]);

  return (
    <>
      <main className="z-0">
        <Header className="z-50" />
        <section>
          <HomeCarousel items={slides} isLoading={isLoading} current={0} autoPlay={true} />
        </section>

        <section className="site-container my-10">
          <h1 className="poppins-bold mb-6 mt-14 text-2xl md:text-4xl [&>span]:text-primary">
            Conoce todos los <span>beneficios</span>
            <br />
            que <span>MovieClub</span> tiene para vos
          </h1>
          <div className="grid grid-flow-row grid-cols-1 gap-4 sm:grid-cols-3 lg:grid-cols-5">
            {benefits.map((benefit, index) => (
              <span key={index + benefit.text}>
                <Benefit img={benefit.img} text={benefit.text} description={benefit.description} />
              </span>
            ))}
          </div>
          <p className="my-5 text-sm">
            Todos los beneficios son válidos para disfrutar únicamente en <strong>cinépolis</strong>
          </p>
        </section>
        <section id="movies" className="w-full bg-neutral-800">
          <div className="site-container py-11">
            <h1 className="poppins-bold text-2xl text-white md:text-4xl">¿Qué Películas Ver?</h1>
            <MoviesBillboard endpoint={billboard} queryKey={['billboard']} />
          </div>
        </section>

        <section className="w-full bg-neutral-900 ">
          <div className="site-container py-11">
            <h1 className="poppins-bold mb-10 text-2xl text-white md:text-4xl">Próximos Estrenos</h1>

            {isLoadingUpcoming &&
              Array.from({ length: 5 }).map((_, index) => <Skeleton key={index} className="h-full min-h-56 w-full" />)}

            {!isLoadingUpcoming && (
              <Carousel>
                <CarouselContent className="min-h-[300px]">
                  {React.Children.toArray(
                    upcoming?.map((item) => (
                      <CarouselItem className="h-[300px] md:basis-1/3 lg:basis-1/5">
                        <div className="flex justify-center">
                          <MoviePoster
                            key={item.id}
                            className="max-w-[154px]"
                            img={item.poster_url}
                            title={item.title_translated}
                            link={`/peliculas/${item.id}`}
                            label={{ text: item.badge_string, className: MovieTag[item.badge_color] }}
                          />
                        </div>
                      </CarouselItem>
                    )),
                  )}
                </CarouselContent>
                <CarouselPrevious />
                <CarouselNext />
              </Carousel>
            )}
          </div>
        </section>

        <section className="w-full bg-primary ">
          <div className="site-container py-20">
            <h1 className="poppins-bold text-2xl text-white md:text-4xl">
              ¡Hacete socio
              <br />
              MovieClub!
            </h1>
            <Link to="/asociate-ahora">
              <Button variant="outline" className="mt-10">
                ¡Asociate Ahora!
              </Button>
            </Link>
          </div>
        </section>

        <section className="w-full ">
          <div className="site-container py-11">
            <h1 className="poppins-bold text-2xl md:text-4xl [&>span]:text-primary">
              Sumá mas <span>beneficios</span>
              <br />a tu <span>suscripción</span> MovieClub
              <br />
              asociándote con
            </h1>
            <BrandsGrid />
          </div>
        </section>

        <section className="w-full bg-muted/40">
          <div className="site-container py-11">
            <h1 className="poppins-bold text-2xl md:text-4xl [&>span]:text-primary">¿Cómo funciona?</h1>
            <ul className="marker:poppins-bold [&>li]:text-normal ml-4 mt-5 list-decimal text-stone-500 marker:font-bold marker:text-primary [&>li>span]:font-bold [&>li>span]:text-primary [&>li]:mb-1">
              <li>
                Hacé click en <span>Asociate Ahora</span>, elegí tu Alianza y comenzá a disfrutar de tus Beneficios. Si
                yas socio, hacé click en <span>Inicar Sesión</span>
              </li>
              <li>Ingresá a tu Cuenta y canjeá tus Beneficios.</li>
              <li>
                Anunciate como <span>socio MovieClub</span> antes de realizar tu compra en el Candy para obtener tu
                Descuento.
              </li>
              <li>¡Disfrutá la película que elegiste!</li>
            </ul>
            <Link to="/asociate-ahora">
              <Button radius="full" className="my-5 mr-5 w-48" variant="default">
                ¡Asociate Ahora!
              </Button>
            </Link>
            {!isAuthenticated && (
              <Button onClick={() => login()} radius="full" className="w-48" variant="secondary">
                Inicar Sesión
              </Button>
            )}
          </div>
        </section>

        <section className="w-full bg-muted">
          <div className="site-container py-11">
            <p className="poppins-bold text-xl text-primary md:text-2xl">¿dudas? ¿preguntas?</p>
            <h1 className="poppins-bold text-2xl md:text-4xl [&>span]:text-primary">Estamos para ayudarte</h1>
            <a href="https://soporte.cinepolis.com.ar/support/home" target="_blank" rel="noopener">
              <Button radius="full" className="my-5 mr-5 w-48" variant="default">
                Contacto
              </Button>
            </a>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
