import { z } from '@/lib/es-zod.ts';
import { states } from '@/lib/states.ts';
import { useMutation, useQuery } from '@tanstack/react-query';
import { addMember, unsubscribeMember, updateMember } from '@/lib/members';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { CalendarIcon, Loader2 } from 'lucide-react';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form.tsx';
import { Input } from '@/components/ui/input.tsx';
import { Popover, PopoverContent } from '@/components/ui/popover.tsx';
import { PopoverTrigger } from '@radix-ui/react-popover';
import { Button } from '@/components/ui/button.tsx';
import { cn } from '@/lib/utils.ts';
import { format, parse } from 'date-fns';
import { es } from 'date-fns/locale';
import { Calendar } from '@/components/ui/calendar.tsx';
import React, { ReactNode, useEffect, useMemo } from 'react';
import { Member } from '@/lib/members/member.ts';
import { getClubs } from '@/lib/clubs';
import SubmitButton from '@/components/ui/submit-button.tsx';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog.tsx';
import { Brand } from '@/lib/brands/contracts.ts';
import { getBrandById } from '@/lib/brands/api.tsx';
import { DEFAULT_CLUB_ID } from '@/lib/config.ts';
import { createResetPasswordLink as _createResetPasswordLink } from '@/lib/auth';

const updateFormSchema = z.object({
  FirstName: z
    .string()
    .min(1, {
      message: 'El nombre es requerido',
    })
    .max(255),
  LastName: z
    .string()
    .min(1, {
      message: 'El apellido es requerido',
    })
    .max(255),
  NationalId: z
    .string()
    .min(1, {
      message: 'El documento es requerido',
    })
    .regex(/^\d+$/, 'Sólo se permiten números'),
  DateOfBirth: z.date({
    message: 'La fecha de nacimiento es requerida',
  }),
  Address1: z
    .string()
    .min(1, {
      message: 'La dirección es requerida',
    })
    .max(255),
  City: z
    .string()
    .min(1, {
      message: 'La ciudad es requerida',
    })
    .max(255),
  State: z.enum(states),
  ZipCode: z
    .string()
    .min(1, {
      message: 'El código postal es requerido',
    })
    .max(255),
  MobilePhone: z
    .string()
    .min(1, {
      message: 'El número de teléfono es requerido',
    })
    .regex(/^\d+$/, 'Sólo se permiten números'),
  // Password: z.string().min(8).optional().or(z.literal('')),
  // ConfirmPassword: z.string().min(8).optional().or(z.literal('')),
});
// .refine(
//   (values) => {
//     return values.Password === values.ConfirmPassword;
//   },
//   {
//     message: 'La contraseña no coincide!',
//     path: ['ConfirmPassword'],
//   },
// );

// Add password and confirm password to the schema
const createFormSchema = z.object({
  FirstName: z
    .string()
    .min(1, {
      message: 'El nombre es requerido',
    })
    .max(255),
  LastName: z
    .string()
    .min(1, {
      message: 'El apellido es requerido',
    })
    .max(255),
  NationalId: z
    .string()
    .min(1, {
      message: 'El documento es requerido',
    })
    .regex(/^\d+$/, 'Sólo se permiten números'),
  DateOfBirth: z.date({
    message: 'La fecha de nacimiento es requerida',
  }),
  Address1: z
    .string()
    .min(1, {
      message: 'La dirección es requerida',
    })
    .max(255),
  City: z
    .string()
    .min(1, {
      message: 'La ciudad es requerida',
    })
    .max(255),
  State: z.enum(states),
  ZipCode: z
    .string()
    .min(1, {
      message: 'El código postal es requerido',
    })
    .max(255),
  MobilePhone: z
    .string()
    .min(1, {
      message: 'El número de teléfono es requerido',
    })
    .regex(/^\d+$/, 'Sólo se permiten números'),
  Email: z.string().email({
    message: 'El email es requerido',
  }),
  // Password: z.string().min(8).max(255),
  // ConfirmPassword: z.string().min(8).max(255),
  externalId: z.string().optional(),
});
// .refine(
//   (values) => {
//     return values.Password === values.ConfirmPassword;
//   },
//   {
//     message: 'La contraseña no coincide!',
//     path: ['confirmPassword'],
//   },
// );

export type UpdateMemberProfileFormValues = z.infer<typeof updateFormSchema>;
export type CreateMemberProfileFormValues = z.infer<typeof createFormSchema>;

export type MemberProfileFormProps = {
  member?: Member;
  clubId?: string;
  header?: ReactNode;
  onSubmitted?: () => void | Promise<void>;
  onUnsubscribed?: () => void | Promise<void>;
};

export default function MemberProfileForm({
  member,
  clubId: initialClubId,
  header,
  onSubmitted = () => {},
  onUnsubscribed = () => {},
}: MemberProfileFormProps) {
  const { data: clubs, isPending: clubsPending } = useQuery({
    queryKey: ['clubs'],
    queryFn: getClubs,
  });

  const clubId = useMemo(() => {
    if (!clubs) {
      return DEFAULT_CLUB_ID;
    }

    const memberClubId: string | undefined = member?.clubId && +member.clubId !== 0 ? member.clubId : undefined;
    let clubId: string = initialClubId ?? memberClubId ?? DEFAULT_CLUB_ID;

    // Validate clubId exists in clubs, else return default clubId
    if (!clubs.find((c) => c.id === clubId)) {
      clubId = DEFAULT_CLUB_ID;
    }

    return clubId;
  }, [clubs, initialClubId, member?.clubId]);

  const {
    mutateAsync: register,
    isPending: isCreating,
    isError: isCreateError,
  } = useMutation({
    mutationKey: ['addMember'],
    mutationFn: (
      data: Omit<Member, 'MemberId' | 'UserName'> & {
        clubId: string;
        // Password: string;
      },
    ) => addMember(data),
  });
  const {
    mutateAsync: update,
    isPending: isUpdating,
    isError: isUpdateError,
  } = useMutation({
    mutationKey: ['updateMember'],
    mutationFn: (data: Omit<UpdateMemberProfileFormValues, 'ConfirmPassword'> & { memberId: string }) =>
      updateMember(data.memberId, data as Omit<UpdateMemberProfileFormValues, 'ConfirmPassword'>),
  });

  const { mutateAsync: unsubscribe, isPending: isUnsubscribing } = useMutation({
    mutationKey: ['unsubscribeMember'],
    mutationFn: () => unsubscribeMember(),
    onSuccess: async () => {
      await onUnsubscribed();
    },
  });

  const {
    mutateAsync: createPasswordLink,
    isPending: isPasswordLinkPending,
    isError: isPasswordLinkError,
  } = useMutation({
    mutationKey: ['passwordLink'],
    mutationFn: () => _createResetPasswordLink(),
    onSuccess: async (result) => {
      window.location.href = result.url;
    },
  });

  const [showUnsubscribeDialog, setShowUnsubscribeDialog] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const brand: Brand | undefined = useMemo(() => {
    return clubId ? getBrandById(+clubId) : undefined;
  }, [clubId]);

  const isPending = (isCreating && !isCreateError) || (isUpdating && !isUpdateError) || clubsPending || isSubmitting;

  const defaultValues = {
    FirstName: '',
    LastName: '',
    NationalId: '',
    DateOfBirth: undefined,
    Address1: '',
    City: '',
    State: undefined,
    ZipCode: '',
    MobilePhone: '',
    Email: '',
    // Password: '',
    // ConfirmPassword: '',
    externalId: '',
  };

  const form = useForm<CreateMemberProfileFormValues | UpdateMemberProfileFormValues>({
    resolver: zodResolver(member?.MemberId ? updateFormSchema : createFormSchema),
    defaultValues: {
      ...defaultValues,
      ...member,
      DateOfBirth: member?.birthDate ? parse(member.birthDate, 'yyyy-MM-dd', new Date(), { locale: es }) : undefined,
      NationalId: member?.documentNumber ?? '',
      Address1: member?.street ?? '',
      City: member?.city ?? '',
      State: member?.state ? (member.state as (typeof states)[number]) : undefined,
      ZipCode: member?.postalCode ?? '',
    },
    disabled: isPending,
  });

  const club = clubs?.find((c) => c.id === clubId);

  async function onSubmit(values: CreateMemberProfileFormValues | UpdateMemberProfileFormValues) {
    setIsSubmitting(true);

    // const { ConfirmPassword, ...data } = values;
    const { ...data } = values;

    const email = member?.Email ?? ('Email' in data ? data.Email : '');
    const isUpdate = !!member?.MemberId;

    await (isUpdate
      ? update({ ...data, memberId: member?.MemberId })
      : register({
          ...data,
          // Password: data.Password ?? '', // satisfy compiler, Password will always be defined
          clubId: clubId,
          Email: email,
        }));

    await onSubmitted();

    setIsSubmitting(false);
  }

  const title = member?.MemberId ? 'Actualizar información' : 'Asociate a ' + (club?.name ?? 'Movie Club');
  const isEmailEditable = !member?.Email;
  const isExternalIdEditable = !member?.externalId;
  const actionLabel = member?.MemberId ? 'Actualizar' : 'Siguiente';

  // Determine if member supports password reset
  const isPasswordResetSupported = !!member?.MemberId && member?.authProviderId?.startsWith('auth0|');

  // Debug clubId
  useEffect(() => {
    console.debug('Using clubId:', clubId);
  }, [clubId]);

  return (
    <>
      <h2 className="text-xl font-bold">{title}</h2>
      <p className="text-sm text-gray-500 sm:pb-1">Todos los campos son requeridos</p>
      <div className="mt-4 w-full rounded-sm border-muted py-6 md:border md:px-8">
        {!!header && <div className="mb-6">{header}</div>}
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-3">
            <h3 className="text-primary">INFORMACIÓN PERSONAL</h3>
            <div className="flex flex-col items-center justify-center xl:flex-row xl:gap-5 [&>*]:w-full [&>*]:xl:w-1/2">
              <FormField
                control={form.control}
                name="FirstName"
                render={({ field }) => (
                  <FormItem className="h-[100px]">
                    <FormLabel>Nombre</FormLabel>
                    <FormControl>
                      <Input placeholder="Nombre" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="LastName"
                render={({ field }) => (
                  <FormItem className="h-[100px]">
                    <FormLabel>Apellido</FormLabel>
                    <FormControl>
                      <Input placeholder="Apellido" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex flex-col items-center justify-center xl:flex-row xl:gap-5 [&>*]:w-full [&>*]:xl:w-1/2">
              <FormField
                control={form.control}
                name="NationalId"
                render={({ field }) => (
                  <FormItem className="h-[100px]">
                    <FormLabel>Documento</FormLabel>
                    <FormControl>
                      <Input placeholder="Documento" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="DateOfBirth"
                render={({ field }) => (
                  <FormItem className="flex h-[80px] flex-col">
                    <FormLabel>Fecha de nacimiento</FormLabel>
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant="outline-default"
                            radius="sm"
                            className={cn('pl-3 text-left font-normal', !field.value && 'text-muted-foreground')}
                            disabled={isPending}
                          >
                            {field.value ? (
                              format(field.value, 'PPP', { locale: es })
                            ) : (
                              <span>Fecha de nacimiento</span>
                            )}
                            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          captionLayout="dropdown-buttons"
                          fromYear={new Date().getFullYear() - 100}
                          toYear={new Date().getFullYear()}
                          mode="single"
                          selected={field.value}
                          onSelect={field.onChange}
                          disabled={(date) => date > new Date() || date < new Date('1900-01-01')}
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <h3 className="text-primary">DATOS ADICIONALES</h3>
            <div className="flex flex-col items-center justify-center xl:flex-row xl:gap-5 [&>*]:w-full [&>*]:xl:w-1/2">
              <FormField
                control={form.control}
                name="Address1"
                render={({ field }) => (
                  <FormItem className="h-[100px]">
                    <FormLabel>Dirección</FormLabel>
                    <FormControl>
                      <Input placeholder="Dirección" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="City"
                render={({ field }) => (
                  <FormItem className="h-[100px]">
                    <FormLabel>Ciudad</FormLabel>
                    <FormControl>
                      <Input placeholder="Ciudad" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex flex-col items-center justify-center lg:flex-row lg:gap-5 [&>*]:w-full [&>*]:lg:w-1/2">
              <FormField
                control={form.control}
                name="State"
                render={({ field }) => (
                  <FormItem className="h-[100px]">
                    <FormLabel>Provincia</FormLabel>
                    <select className="select select-bordered w-full" {...field}>
                      <option disabled selected>
                        Provincia
                      </option>
                      {states?.map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="ZipCode"
                render={({ field }) => (
                  <FormItem className="h-[100px]">
                    <FormLabel>Código postal</FormLabel>
                    <FormControl>
                      <Input placeholder="Código postal" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex flex-col items-center justify-center lg:flex-row lg:gap-5 [&>*]:w-full [&>*]:lg:w-1/2">
              <FormField
                control={form.control}
                name="MobilePhone"
                render={({ field }) => (
                  <FormItem className="h-[100px]">
                    <FormLabel>Teléfono</FormLabel>
                    <FormControl>
                      <Input placeholder="Teléfono" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="Email"
                render={({ field }) => (
                  <FormItem className="h-[100px]">
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <>
                        {isEmailEditable && (
                          <Input
                            placeholder="Email"
                            type="email"
                            {...field}
                            readOnly={!isEmailEditable}
                            {...(!isEmailEditable ? { autoComplete: 'off' } : { autoComplete: 'on' })}
                          />
                        )}
                        {!isEmailEditable && <p className="py-2 text-gray-400">{member?.Email}</p>}
                      </>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            {/*<SeparatorTitle title="SUS CREDENCIALES" />*/}
            {/*<div className="flex flex-col items-center justify-center sm:flex-row sm:gap-5 [&>*]:w-full [&>*]:sm:w-1/2">*/}
            {/*  <FormField*/}
            {/*    control={form.control}*/}
            {/*    name="Password"*/}
            {/*    render={({ field }) => (*/}
            {/*      <FormItem className="h-[100px]">*/}
            {/*        <FormLabel>Contraseña</FormLabel>*/}
            {/*        <FormControl>*/}
            {/*          <Input type="password" placeholder="Contraseña" {...field} />*/}
            {/*        </FormControl>*/}
            {/*        <FormMessage />*/}
            {/*      </FormItem>*/}
            {/*    )}*/}
            {/*  />*/}
            {/*  <FormField*/}
            {/*    control={form.control}*/}
            {/*    name="ConfirmPassword"*/}
            {/*    render={({ field }) => (*/}
            {/*      <FormItem className="h-[100px]">*/}
            {/*        <FormLabel>Confirmar contraseña</FormLabel>*/}
            {/*        <FormControl>*/}
            {/*          <Input type="password" placeholder="Confirmar contraseña" {...field} />*/}
            {/*        </FormControl>*/}
            {/*        <FormMessage />*/}
            {/*      </FormItem>*/}
            {/*    )}*/}
            {/*  />*/}
            {/*</div>*/}
            {brand?.extraField && (
              <div className="flex flex-col items-center justify-start lg:flex-row lg:gap-5 [&>*]:w-full [&>*]:lg:w-1/2">
                <FormField
                  control={form.control}
                  name="externalId"
                  render={({ field }) => (
                    <FormItem className="h-[100px]">
                      <FormLabel>{brand?.extraField?.label}</FormLabel>
                      <FormControl>
                        <>
                          {isExternalIdEditable && <Input placeholder="" {...field} />}
                          {!isExternalIdEditable && <p className="py-2 text-gray-400">{member?.externalId}</p>}
                        </>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="hidden lg:block">&nbsp;</div>
              </div>
            )}
            <div className="flex flex-col lg:flex-row lg:items-start">
              {!!member?.MemberId && (
                <div>
                  {isPasswordResetSupported && (
                    <div>
                      <Button
                        type="button"
                        variant="link"
                        className="p-0"
                        onClick={() => {
                          createPasswordLink();
                        }}
                        disabled={isPasswordLinkPending && !isPasswordLinkError}
                      >
                        {isPasswordLinkPending && !isPasswordLinkError && (
                          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        )}
                        Cambiar contraseña
                      </Button>
                    </div>
                  )}
                  <Dialog open={showUnsubscribeDialog} onOpenChange={setShowUnsubscribeDialog}>
                    <DialogTrigger asChild>
                      <Button type="button" variant="link" className="p-0" disabled={isPending}>
                        Baja de Suscripción
                      </Button>
                    </DialogTrigger>
                    <DialogContent>
                      <DialogHeader>
                        <DialogTitle>¿Está seguro de que se quiere dar de baja?</DialogTitle>
                        <DialogDescription className="pt-2">
                          <div className="flex flex-col space-y-2">
                            <p>Al confirmar la operacion quedará registrada su solicitud para el proximo mes.</p>
                            <p>Usted podrá continuar utilizando los beneficios hasta el ultimo día del mes en curso.</p>
                            <p>
                              Una vez generada la baja, Ud. no podrá crear una nueva cuenta utilizando el mail y la
                              tarjeta registrados en esta cuenta. En caso de querer reactivar su cuenta, deber completar
                              nuestro formulario de contacto.
                            </p>
                          </div>
                          <div className="flex w-full justify-end pt-5">
                            <Button
                              size="sm"
                              variant="destructive"
                              className="px-5"
                              onClick={async () => {
                                try {
                                  await unsubscribe();
                                } finally {
                                  setShowUnsubscribeDialog(false);
                                }
                              }}
                              disabled={isUnsubscribing}
                            >
                              {isUnsubscribing && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                              Confirmar
                            </Button>
                          </div>
                        </DialogDescription>
                      </DialogHeader>
                    </DialogContent>
                  </Dialog>
                </div>
              )}
              <div className="flex-1"></div>
              <SubmitButton label={actionLabel} isPending={isPending} className="mt-4 lg:mt-0" />
            </div>
          </form>
        </Form>
      </div>
    </>
  );
}
