import InnerApp from '@/InnerApp';
import { Auth0Provider } from '@auth0/auth0-react';
import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN, GA_MEASUREMENT_ID } from '@/lib/config';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import React from 'react';
import { validateRedirectState } from '@/lib/auth';
import { queryClient } from '@/lib/query-client';
import { QueryClientProvider, QueryErrorResetBoundary } from '@tanstack/react-query';
import moment from 'moment/min/moment-with-locales';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ErrorComponent } from '@/components/common/error-component.tsx';
import ReactGA from 'react-ga4';

Bugsnag.start({
  apiKey: '77f0da7d1444cfd4da8ee078bc883b23',
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.NODE_ENV,
  enabledReleaseStages: ['production', 'staging'],
});

BugsnagPerformance.start({ apiKey: '77f0da7d1444cfd4da8ee078bc883b23' });

// Get redirect from querystring or use the current url
const redirectUri = window.location.origin;
const audience = AUTH0_AUDIENCE;

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

moment.locale('es');

ReactGA.initialize([
  {
    trackingId: GA_MEASUREMENT_ID,
    gaOptions: {
      testMode: process.env.NODE_ENV !== 'production',
    },
  },
]);

function fallbackRender({ error, notify = true }: { error: Error; notify?: boolean }) {
  return <ErrorComponent error={error} notify={notify} />;
}

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <QueryErrorResetBoundary>
        {() => (
          <ErrorBoundary
            FallbackComponent={({ error }) =>
              fallbackRender({
                error,
                notify: false, // Notify will be handled by the ErrorBoundary
              })
            }
          >
            <HelmetProvider>
              <Auth0Provider
                domain={AUTH0_DOMAIN}
                clientId={AUTH0_CLIENT_ID}
                authorizationParams={{
                  redirect_uri: redirectUri,
                  audience,
                }}
                onRedirectCallback={(appState) => {
                  // Use the state to redirect to the correct page after login
                  const redirectTo = validateRedirectState(appState);
                  window.location.replace(redirectTo ?? appState?.returnTo ?? window.location.pathname);
                }}
                cacheLocation={'localstorage'}
              >
                <Helmet>
                  <title>MovieClub | Otra Manera de Vivir el Cine</title>
                </Helmet>
                <InnerApp />
              </Auth0Provider>
            </HelmetProvider>
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
    </QueryClientProvider>
  );
}
