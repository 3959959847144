import { cn } from '@/lib/utils';
import { Link } from '@tanstack/react-router';
import { Plus } from 'lucide-react';

type Props = {
  className?: string;
  title?: string;
  img?: string;
  link?: string;
  label?: { text: string; className: string };
};

export default function MoviePoster({ className, img, label, title, link }: Props) {
  return (
    <div className={cn('relative h-auto w-full', className)}>
      <div className="relative aspect-[3/4.5] w-full overflow-hidden rounded-lg">
        <Link
          to={link}
          className="absolute left-0 top-0 z-40 flex h-full w-full items-center justify-center bg-black/30 opacity-0 transition-all duration-300 ease-in-out hover:opacity-100"
        >
          <Plus size={32} className="text-white" />
        </Link>
        <div
          style={{ backgroundImage: `url(${img})` }}
          className="h-full min-h-[200px] w-full min-w-[154px] bg-muted bg-cover bg-center bg-no-repeat"
        />
      </div>
      {!!label && (
        <div
          className={cn(
            'absolute left-2 top-2 rounded-sm px-4 py-2 text-center text-xs uppercase text-white',
            label?.className,
          )}
        >
          {label.text}
        </div>
      )}
      {!!title && <p className="poppins-bold mt-2 w-full text-center text-sm text-white">{title}</p>}
    </div>
  );
}
